<!--
 * @Author: kevinzgai zhanggai_ok@126.com
 * @Date: 2023-11-27 14:37:26
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2023-12-04 16:23:32
 * @FilePath: \zyt-zlb-mobile-develop\src\views\cinemaAssistant\competitorCinema\boxOffice.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <van-tabs color="#1989fa" :active="activeTab" @change="tabChange">
      <van-tab title="昨天" name="yesterday"> </van-tab>
      <van-tab title="近7天" name="before7days"> </van-tab>
      <van-tab title="近30天" name="before30days"> </van-tab>
    </van-tabs>
    <div>
      <ve-table
        :scroll-width="900"
        :table-data="tableData"
        :max-height="maxHeight"
        :fixed-header="true"
        :columns="columns"
      ></ve-table>
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
export default {
  data() {
    return {
      activeTab: 'yesterday',
      ourCinemaId: null,
      // 表格最大高度
      maxHeight: 900,
      tableData: [],
      ourCinema: {},
      columns: [
        // { field: "name", key: "a", title: "Name", align: "center" },
        {
          title: '竞对影院名称',
          field: 'cinemaName',
          key: 'cinemaName',
          width: '120px',
          fixed: 'left',
        },
        {
          title: '影院位置',
          field: 'cinemaLocation',
          key: 'cinemaLocation',
          width: '200px',
        },
        {
          title: '距离当前影院距离',
          field: 'distance',
          key: 'distance',
          width: '200px',
        },
        {
          title: '平均票价',
          field: 'averagePrice',
          key: 'averagePrice',
          width: '100px',
        },
        {
          title: '最低票价',
          field: 'lowestPrice',
          key: 'lowestPrice',
          width: '100px',
        },
        {
          title: '最高票价',
          field: 'highestPrice',
          key: 'highestPrice',
          width: '100px',
        },
        // 详情
        {
          title: '详情',
          field: 'detail',
          key: 'detail',
          width: '50px',
          fixed: 'right',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field]
            // console.log({ row, column, rowIndex });
            if (rowIndex === 0) {
              return <span>-</span>
            } else {
              return (
                <span
                  style="color: #1989fa;cursor: pointer;"
                  onClick={() => {
                    this.$router.push({
                      path: '/cinemaAssistant/competitorCinema/boxOfficeDetail',
                      query: {
                        id: row.id,
                        ourCinema: JSON.stringify(this.ourCinema),
                      },
                    })
                  }}
                >
                  详情
                </span>
              )
            }
          },
        },
      ],
    }
  },
  mounted() {
    this.ourCinemaId = this.$route.query.cinemaId
    this.getData()
    // 动态计算表格最大高度
    this.maxHeight = window.innerHeight - 10
  },
  methods: {
    // tab切换
    tabChange(e) {
      console.log(e)
      this.activeTab = e
      this.getData()
    },
    // 获取数据
    getData() {
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.ourCinemaId,
        dateType: this.activeTab,
      }
      // 获取数据
      zjApi.getAnalysisPrice(query).then((res) => {
        console.log(res)
        this.tableData = res
        if (res.length > 0) {
          this.ourCinema = res[0]
        }
      })
    },
  },
}
</script>
